import { Col, Dropdown, Input, Layout, Menu, Row } from "antd";
import { default as React, useCallback, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../../../assets/images/svg/close.svg";
import { translateSearchbarOptions } from "../../../../helpers/header/translateSearchbarOptions";
import { useOmnisearch } from "../../../../hooks/useOmnisearch";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import i18n from "../../../../language/i18n";
import { App } from "../../../../model/app/App";
import { AuthUser } from "../../../../model/auth/AuthUser";
import { Claims } from "../../../../model/auth/Claims";
import { Notification } from "../../../../model/notification/notification";
import { OmniType } from "../../../../model/omni/OmniType";
import authentication from "../../../../services/authentication";
import NotificationsService from "../../../../services/NotificationService";
import HeaderTabs from "../../../base/headerTabs";
import AppsIcon from "../../../base/icon/apps";
import BurgerIcon from "../../../base/icon/burger";
import ChevronDown from "../../../base/icon/chevronDown";
import CloseIcon from "../../../base/icon/close";
import IntouchIcon from "../../../base/icon/intouch";
import NotificationIcon from "../../../base/icon/notification";
import SearchIcon from "../../../base/icon/search";
import Profile from "../../../base/profile";
import Spinner from "../../../base/spinner";
import B2 from "../../../base/text/b2";
import Paragraph from "../../../base/text/paragraph";
import AppList from "../appList";
import styles from "./index.module.css";
import NotificationCard from "./notificationCard";

export interface HeaderProps {
    className?: string;
    myKMHubLink?: string;
    user?: AuthUser;
    onSelectSidebar?: (open: boolean) => void;
    apps?: Array<App>;
    sidebarOpen: any;
}

const onLogout = async (event: any) => {
    event.preventDefault();
    authentication.logout();
};

const onmyKMHubItemClick = async (event: any, myKMHubLink?: string) => {
    event.preventDefault();
    window.open(myKMHubLink);
};

const settingsMenu = (myKMHubLink?: string) => (
    <Menu className={styles.menu}>
        <Menu.Item key="0" className={styles.menuItem}>
            <a className={"noDecoration"} href="/" onClick={(event) => onmyKMHubItemClick(event, myKMHubLink)}>
                {" "}
                {i18n.t("homePage.myKMHub")}{" "}
            </a>
        </Menu.Item>
        <Menu.Item key="1" className={styles.menuItem}>
            <a className={"noDecoration"} href="/logout" onClick={onLogout}>
                {" "}
                {i18n.t("homePage.logout")}{" "}
            </a>
        </Menu.Item>
    </Menu>
);


const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const [selectedNotification, setSelectedNotification] =
        useState<boolean>(false);
    const [showSearchbarOptions, setShowSearchbarOptions] =
        useState<boolean>(false);
    const [searchbarFocused, setSearchbarFocused] = useState(false);
    const { isPhone } = useWindowSize();
    const [searchbarOptions] = useState<any[]>([
        { name: "Pesquisa geral", id: OmniType.ALL },
        { name: "Aplicações", id: OmniType.APPLICATIONS },
        { name: "Categorias", id: OmniType.CATEGORIES },
        { name: "Eventos", id: OmniType.EVENTS },
        { name: "Equipas", id: OmniType.TEAMS },
        { name: "Notícias", id: OmniType.NEWS },
        { name: "Páginas", id: OmniType.PAGES },
        { name: "Parcerias", id: OmniType.PARTNERSHIPS },
        { name: "Pessoas", id: OmniType.USERS },
        { name: "Vídeos/Podcasts", id: OmniType.BROADCASTS },
    ]);
    const { setQuery, setType, setSize, query, type, data, loading } =
        useOmnisearch(2);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [newNotification, setNewNotification] = useState<boolean>(false);
    const [headerBackgroundTransparency, setheaderBackgroundTransparency] =
        useState<boolean>(false);
    const history = useHistory();
    const isResultsPageCurrent = history.location.pathname === "/resultados";
    const claims = [Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER];
    const hasClaims =
        props.user?.claims &&
        claims.some((claim) => props.user?.claims && !!props.user?.claims[claim]);

    useEffect(() => {
        if (props.user?.auth?.uid) {
            NotificationsService.updateNotifications(props.user.auth.uid, (docs) => {
                const mappedDocs = docs.docs.map((doc) => doc.data()) as Notification[];
                const lastNotification = localStorage.getItem("lastNotification");

                if (mappedDocs.length && lastNotification !== docs.docs[0].id) {
                    localStorage.setItem("lastNotification", docs.docs[0].id);
                    setNewNotification(true);
                }

                setNotifications(mappedDocs);
            });
        }
    }, [props.user]);

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    });

    function changeBackground() {
        if (window.scrollY >= 1) {
            setheaderBackgroundTransparency(false);
        } else {
            setheaderBackgroundTransparency(true);
        }
    }

    const escFunction = useCallback(
        (e) => {
            if (e.key === "Escape" && searchbarFocused === true) {
                onClickCloseOverlay();
            }

            // eslint-disable-next-line
        }, [searchbarFocused]
    );

    useEffect(() => {
        document.body.addEventListener("keydown", escFunction);
    }, [escFunction]);

    const onClickSidebar = () => {
        // setOpenSideabar(!openSidebar);
        props.onSelectSidebar && props.onSelectSidebar(!props.sidebarOpen);
    };

    const onClickNotifications = (visible: boolean) => {
        setSelectedNotification(visible);
        const bodyEl = document.querySelector("body");
        bodyEl && bodyEl.classList.toggle(styles.noscroll, visible);
        newNotification && setNewNotification(false);
    };

    const handleNotificationCardClick = () => {
        const bodyEl = document.querySelector("body");
        bodyEl && bodyEl.classList.remove(styles.noscroll);
        setSelectedNotification(false);
    };

    const notificationCount = notifications.length;

    const notificationMenu = (
        <div
            className={[
                styles.menuContainer,
                notificationCount > 3 && styles.overflowActive,
            ].join(" ")}
        >
            <Menu className={styles.menu}>
                {notificationCount ? (
                    notifications.map((item, index) => (
                        <NotificationCard
                            key={index}
                            item={item}
                            onClick={handleNotificationCardClick}
                        />
                    ))
                ) : (
                    <div className={styles.noNotification}>
                        <B2>{i18n.t("header.notifications.noNotifications")}</B2>
                    </div>
                )}
            </Menu>

            <Row className={styles.seeAllCategories}>
                <Col span={24}>
                    <Link to="/categorias" onClick={handleNotificationCardClick}>
                        <B2>{i18n.t("header.notifications.seeCategories")}</B2>
                    </Link>
                </Col>
            </Row>
        </div>
    );

    const onClickBrand = () => {
        history.push("/");
    };

    const isLightText = document
        .getElementById("mainHeader")
        ?.classList.contains("lightTextHeader");
    const isDarkText = document
        .getElementById("mainHeader")
        ?.classList.contains("darkTextHeader");
    let headerClasses = [
        styles.header,
        isLightText ? "lightTextHeader" : null,
        isDarkText ? "darkTextHeader" : null,
    ];
    let icon = <BurgerIcon onClick={onClickSidebar} />;

    if (searchbarFocused === true) {
        headerClasses.push(styles.focusedSearchbarHeader);
    }

    if (props.sidebarOpen === true) {
        headerClasses.push(styles.sidebarOpen);
        icon = <CloseIcon onClick={onClickSidebar} />;
    }

    function onSearchbarOptionClick(props: any) {
        setType(props.key);

        if (props.key === OmniType.ALL) {
            setSize(2);
        } else {
            setSize(10);
        }

        setShowSearchbarOptions(false);
    }

    function onClickCloseOverlay() {
        const webSearchbar = document.getElementById("webSearchbar");
        document.body.style.overflow = "unset";
        setSearchbarFocused(false);
        setQuery("");
        setType(OmniType.ALL);
        webSearchbar?.blur();
    }

    function onSearchbarFocus() {
        document.body.style.overflow = "hidden";
        setSearchbarFocused(true);
    }

    function onSearchbarChange(e: any) {
        const { value } = e.target;
        setQuery(value);
    }

    function onMobileSearchIconClick() {
        document.body.style.overflow = "hidden";
        setSearchbarFocused(true);

        const mobileSearchbar = document.getElementById("mobileSearchbar");

        if (mobileSearchbar) {
            const __tempEl__ = document.createElement("input");
            __tempEl__.style.position = "absolute";
            __tempEl__.style.top = mobileSearchbar.offsetTop + 7 + "px";
            __tempEl__.style.left = mobileSearchbar.offsetLeft + "px";
            __tempEl__.style.height = "0";
            __tempEl__.style.opacity = "0";

            document.body.appendChild(__tempEl__);
            __tempEl__.focus();

            setTimeout(() => {
                mobileSearchbar.focus();
                mobileSearchbar.click();
                document.body.removeChild(__tempEl__);
            }, 300);
        }
    }

    function onClickSearch() {
        if (typeof query !== "string" || query.length < 3) {
            return;
        }

        const searchInput = query;
        const typeInput = type;

        document.body.style.overflow = "unset";
        setSearchbarFocused(false);
        setQuery("");
        setType(OmniType.ALL);

        history.push(`/resultados?texto=${searchInput}&tipo=${typeInput}`);
    }

    function onSearchResultSuggestionClick(link?: string) {
        if (!link) {
            return;
        }

        document.body.style.overflow = "unset";
        setSearchbarFocused(false);
        setQuery("");
        setType(OmniType.ALL);
        if (link.includes("http")) {
            window.open(link);
        } else {
            history.push(link);
        }
    }

    function renderSearchbarOptions() {
        return (
            <Menu
                className={styles.menu}
                onClick={onSearchbarOptionClick}
                defaultSelectedKeys={[OmniType.ALL]}
                selectedKeys={[type]}
            >
                {searchbarOptions.map((option) => (
                    <Menu.Item key={option.id}>{option.name}</Menu.Item>
                ))}
            </Menu>
        );
    }

    function renderSearchBar() {
        let content;
        if (isPhone) {
            content = (
                <>
                    <div className={styles.searchBar}>
                        <Input
                            id="mobileSearchbar"
                            prefix={<SearchIcon />}
                            placeholder={i18n.t("omnisearch.placeholder")}
                            value={query}
                            onChange={onSearchbarChange}
                            onPressEnter={onClickSearch}
                        />
                    </div>
                    <Close className={styles.close} onClick={onClickCloseOverlay} />
                </>
            );
        } else {
            const renderSearchButton =
                searchbarFocused && query !== "" && query.length >= 3;
            content = (
                <>
                    <Dropdown
                        overlay={() => renderSearchbarOptions()}
                        trigger={["click"]}
                        visible={showSearchbarOptions}
                        onVisibleChange={(visible: boolean) =>
                            setShowSearchbarOptions(visible)
                        }
                        placement="bottomCenter"
                        overlayClassName={styles.searchbarDropdown}
                    >
                        <Row
                            justify="center"
                            align="middle"
                            className={styles.searchbarDropdownRow}
                        >
                            <Paragraph className={styles.searchbarDropdownTitle}>
                                {searchbarOptions.find((opt) => opt.id === type).name}
                            </Paragraph>
                            <ChevronDown />
                        </Row>
                    </Dropdown>
                    <div className={styles.searchBar}>
                        <Input
                            id="webSearchbar"
                            prefix={<SearchIcon />}
                            placeholder={i18n.t("omnisearch.placeholder")}
                            value={query}
                            onChange={onSearchbarChange}
                            onFocus={onSearchbarFocus}
                            onPressEnter={onClickSearch}
                        />
                    </div>
                    {searchbarFocused && (
                        <Paragraph
                            className={[
                                styles.searchButton,
                                renderSearchButton && styles.displaySearchButton,
                            ].join(" ")}
                            onClick={onClickSearch}
                        >
                            {" "}
                            Pesquisar{" "}
                        </Paragraph>
                    )}
                    {searchbarFocused && (
                        <Close className={styles.close} onClick={onClickCloseOverlay} />
                    )}
                </>
            );
        }
        return (
            <div
                className={[
                    styles.searchbarGrid,
                    isResultsPageCurrent && styles.hideSearchbar,
                ].join(" ")}
            >
                {content}
            </div>
        );
    }

    function renderSearchbarResults() {
        const results: { title: string; type: OmniType; link?: string }[] =
            Object.keys(data.response).reduce((acc, curr) => {
                const { response, total } = data.response[curr] || {
                    total: 0,
                    response: [],
                };

                if (total === 0) {
                    return acc;
                }

                let mappedResponse: any[] = [];
                switch (curr) {
                    case OmniType.USERS:
                        mappedResponse = response.map((user) => ({
                            title: user.name,
                            type: OmniType.USERS,
                            link: `/quem-e-quem?texto=${encodeURIComponent(user.name)}`,
                        }));
                        break;
                    case OmniType.BROADCASTS:
                        mappedResponse = response.map((broadcast) => ({
                            title: broadcast.title,
                            type: broadcast.type === "video" ? "Vídeos" : "Podcasts",
                            link: `/broadcast/${broadcast.id}`,
                        }));
                        break;
                    case OmniType.NEWS:
                        mappedResponse = response.map((news) => ({
                            title: news.title,
                            type: OmniType.NEWS,
                            link: `/post/${news.id}`,
                        }));
                        break;
                    case OmniType.EVENTS:
                        mappedResponse = response.map((event) => ({
                            title: event.title,
                            type: OmniType.EVENTS,
                            link: `/agenda/resultados?query=${event.title}`,
                        }));
                        break;
                    case OmniType.CATEGORIES:
                        mappedResponse = response.map((category) => ({
                            title: category.name,
                            type: OmniType.CATEGORIES,
                            link: `/categorias?texto=${category.name}`,
                        }));
                        break;
                    case OmniType.PAGES:
                        mappedResponse = response.map((page) => {
                            let doc = new DOMParser().parseFromString(
                                page.title,
                                "text/html"
                            );
                            return {
                                title: doc.body.textContent,
                                type: OmniType.PAGES,
                                link: `/page/${page.link}`,
                            };
                        });
                        break;
                    case OmniType.TEAMS:
                        mappedResponse = response.map((team) => ({
                            title: team.name,
                            type: OmniType.TEAMS,
                            link: `/equipas?departamento=${encodeURIComponent(team.name)}`,
                        }));
                        break;
                    case OmniType.PARTNERSHIPS:
                        mappedResponse = response.map((partnership) => ({
                            title: partnership.title,
                            type: OmniType.PARTNERSHIPS,
                            link: `/plmj-plus`,
                        }));
                        break;
                    case OmniType.APPLICATIONS:
                        mappedResponse = response.map((application) => ({
                            title: application.title,
                            type: OmniType.APPLICATIONS,
                            link: application.link,
                        }));
                }

                return acc.concat(...mappedResponse);
            }, []);

        const renderSuggestionMessage = results.length > 0;
        const renderShortQueryMessage = query.length < 3;
        const renderNoResultsMesage =
            results.length === 0 && !loading && query !== "" && query.length >= 3;
        const renderSpinner =
            results.length === 0 && loading && query !== "" && query.length >= 3;
        const renderResults = results.length > 0;
        return (
            <div
                className={[
                    styles.searchOverlay,
                    searchbarFocused && styles.displaySearchOverlay,
                ].join(" ")}
            >
                <div className={styles.searchContainer}>
                    {isPhone && (
                        <Dropdown
                            overlay={() => renderSearchbarOptions()}
                            trigger={["click"]}
                            visible={showSearchbarOptions}
                            onVisibleChange={(visible: boolean) =>
                                setShowSearchbarOptions(visible)
                            }
                            placement="bottomCenter"
                            overlayClassName={styles.searchbarDropdown}
                        >
                            <Row
                                justify="center"
                                align="middle"
                                className={styles.searchbarDropdownRow}
                            >
                                <Paragraph className={styles.searchbarDropdownTitle}>
                                    {searchbarOptions.find((opt) => opt.id === type).name}
                                </Paragraph>
                                <ChevronDown className={styles.searchbarDropdownChevron} />
                            </Row>
                        </Dropdown>
                    )}{!isPhone && (
                        <Paragraph className={styles.searchSuggestionsMessage}>
                            {renderSuggestionMessage && "Resultados:"}
                        </Paragraph>
                    )}
                    {renderResults && (
                        <div
                            className={[
                                styles.searchResultsContainer,
                                loading ? styles.containerLoading : undefined,
                            ].join(" ")}
                        >
                            {results.map((result) => {
                                return (
                                    <Row className={styles.searchResultsItem}>
                                        <Highlighter
                                            className={[
                                                styles.searchResultTitle,
                                                styles.searchResultTitleClickable,
                                            ].join(" ")}
                                            highlightClassName={styles.searchResultTitleHighlight}
                                            searchWords={[query]}
                                            onClick={() => onSearchResultSuggestionClick(result.link)}
                                            autoEscape={true}
                                            textToHighlight={result.title}
                                        />
                                        <Paragraph
                                            className={styles.searchResultType}
                                        >{`(${translateSearchbarOptions(result.type)})`}</Paragraph>
                                    </Row>
                                );
                            })}
                        </div>
                    )}
                    {renderShortQueryMessage && (
                        <Paragraph className={styles.noResultsMesage}>
                            Termo a pesquisar tem de ter pelo menos três caracteres
                        </Paragraph>
                    )}
                    {renderNoResultsMesage && (
                        <Paragraph className={styles.noResultsMesage}>
                            Sem resultados
                        </Paragraph>
                    )}
                    {renderSpinner && (
                        <div className={styles.centerSpinner}>
                            {" "}
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return hasClaims ? (
        <>
            <Layout.Header
                className={headerClasses.join(" ")}
                style={{
                    padding: 0,
                    zIndex: 999,
                    position: "fixed",
                    width: "100vw",
                    backgroundColor: headerBackgroundTransparency
                        ? "transparent"
                        : "white",
                }}
                id="mainHeader"
            >
                <div className={styles.headerGrid}>
                    {React.cloneElement(icon, {
                        className: [
                            styles.icon,
                            styles.end,
                            styles.burger,
                            styles.desktop,
                        ].join(" "),
                    })}
                    <div className={styles.menuGrid}>
                        {React.cloneElement(icon, {
                            className: [
                                styles.icon,
                                styles.end,
                                styles.burger,
                                styles.mobile,
                            ].join(" "),
                        })}
                        <IntouchIcon
                            onClick={onClickBrand}
                            className={[styles.icon, styles.logo, styles.start].join(" ")}
                        />
                    </div>
                    {renderSearchBar()}
                    <div className={styles.appsGrid}>
                        <SearchIcon
                            className={[
                                styles.icon,
                                styles.search,
                                isResultsPageCurrent && styles.hideSearchIcon,
                            ].join(" ")}
                            onClick={onMobileSearchIconClick}
                        />
                        <Dropdown
                            overlay={notificationMenu}
                            onVisibleChange={onClickNotifications}
                            placement="bottomCenter"
                            overlayClassName={styles.dropdown}
                            trigger={["click"]}
                        >
                            <div className={styles.notificationIconContainer}>
                                {newNotification && <div className={styles.newNotification} />}
                                <NotificationIcon
                                    className={[styles.icon, styles.notificationIcon].join(" ")}
                                    selected={selectedNotification}
                                    onClick={(e: any) => e.preventDefault()}
                                />
                            </div>
                        </Dropdown>
                        <Dropdown
                            overlay={settingsMenu(props.myKMHubLink)}
                            placement="bottomCenter"
                            overlayClassName={[styles.dropdown, styles.settings].join(" ")}
                            trigger={["click"]}
                        >
                            <Profile
                                className={[styles.icon, styles.profile].join(" ")}
                                user={props.user}
                                onClick={(e: any) => e.preventDefault()}
                            />
                        </Dropdown>
                    </div>
                    <Dropdown
                        overlay={<AppList apps={props.apps} />}
                        placement="bottomRight"
                        overlayClassName={styles.appsDropdown}
                        trigger={["click"]}
                    >
                        <AppsIcon
                            className={[styles.icon, styles.apps].join(" ")}
                            onClick={(e: any) => e.preventDefault()}
                        />
                    </Dropdown>
                </div>
                {renderSearchbarResults()}
            </Layout.Header>
            <div className={styles.tabsContainer}>
                <div
                    className={styles.headerGrid}
                    style={{
                        display: "block",
                        gridTemplateColumns: "initial",
                        height: "30px",
                        marginTop: "20px",
                        transition: "ease-in-out opacity 0.2s, ease-in-out width 0.2s",
                    }}
                >
                    <HeaderTabs user={props.user} />
                </div>
            </div>
        </>
    ) : null;
};

export default Header;
