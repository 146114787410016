import React from 'react';
import AuthenticationContext from '../authenticationContext';
import authentication from '../../../services/authentication';
import { AuthUser } from '../../../model/auth/AuthUser';
// import { Sidebar } from '../../../sections/sidebar';
import { Layout, message } from 'antd';
import Header from '../../sections/standalone/header';
import Footer from '../../sections/footer';
import Sidebar from '../../sections/standalone/sidebar';
import settingsService from '../../../services/settingsService';
import B2 from '../../base/text/b2';
import i18n from '../../../language/i18n';
import HomepageService from '../../../services/homepageService';
import { App } from '../../../model/app/App';
import BackToTop from '../../sections/standalone/backToTop';
import LoginFailedEventService from '../../../services/loginFailedEventService';
import { FirebaseError } from 'firebase';
import { ErrorCode } from '../../../core/ErrorCode';
import { MAXIMUM_LOGIN_RETRIES } from '../../../model/loginFailedEvent/LoginFailedEvent';
import UsersService from '../../../services/usersService';
import { departmentsGovernanceService, departmentsManagementService, departmentsPracticeService } from '../../../services/departmentsService';

const { Content } = Layout;

export type WithAuthenticationState = { authUser: AuthUser, open: boolean, sidebar: Array<object>, myKMHubLink?: string, headerApps: Array<App> }

/**
 * High Order Component that passed down authentication information to all interested parties
 * 
 * @param Component the component to pass down
 */
const withAuthentication = (Component: any) => {
    class WithAuthentication extends React.Component<any, WithAuthenticationState> {

        unsubscribe: firebase.Unsubscribe | undefined;

        updateWebVersion: Promise<void> | undefined;

        constructor(props: any) {
            super(props);
            this.state = {
                authUser: {
                    accessDenied: false,
                    loading: true,
                    auth: null,
                    claims: undefined
                },
                open: false,
                sidebar: [],
                myKMHubLink: undefined,
                headerApps: []
            };
        }

        componentDidUpdate() {
            settingsService.updateWebVersion((doc) => {
                const currentVersion = doc.data() as { version: Number } | undefined;
                const localStorageVersion = localStorage.getItem("currentVersion");

                if (!currentVersion) {
                    return;
                }

                if (localStorageVersion === undefined || localStorageVersion === null) {
                    localStorage.setItem("currentVersion", currentVersion.version.toString());
                    return;
                }

                if (currentVersion.version > Number(localStorageVersion)) {
                    localStorage.setItem("currentVersion", currentVersion.version.toString());
                    if (window.innerWidth < 768) {

                        message.info(
                            {
                                content: (
                                    (
                                        <B2> {i18n.t("notifications.newVersionMobile")} </B2>
                                    )
                                ),
                                style: {
                                    position: 'fixed',
                                    width: "100%",
                                    bottom: 0
                                },
                                duration: 600,
                                onClick: () => {
                                    window.location.reload()
                                }
                            }
                        );
                    } else {
                        message.info(
                            {
                                content: (
                                    (
                                        <>
                                            <B2> {i18n.t("notifications.newVersion")} </B2>
                                            <a
                                                style={{ color: "black" }}
                                                href={window.location.href}
                                            >
                                                {i18n.t("refresh.title")}.
                                            </a>
                                        </>
                                    )
                                ),
                                style: {
                                    marginTop: "90vh"
                                },
                                duration: 600
                            }
                        );
                    }
                }
            });
        }

        componentDidMount() {
            this.setState({
                authUser: {
                    accessDenied: false,
                    loading: true,
                    auth: null,
                    claims: undefined
                }
            }, () => {
                this.unsubscribe = authentication.useFirebaseAuthentication(async authUser => {
                    if (!authUser) {
                        this.setState({ authUser: { loading: false, auth: null, accessDenied: false } })
                        return;
                    }

                    let claims = undefined;
                    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
                    const getClaims = async (retry: number = 0): Promise<{status: boolean, message?: string, retries?: number, claims?: object }> => {
                        try {
                            if (retry > MAXIMUM_LOGIN_RETRIES) {
                                return { status: false, message: ErrorCode.REQUEST_TIMEOUT, retries: retry };
                            }

                            claims = (await authUser.getIdTokenResult(true)).claims

                            if (claims === undefined) {
                                retry++;
                                await delay(3000);
                                return await getClaims(retry);
                            }

                            const hasViewerPermission = (claims.editor === true || claims.viewer === true || claims.administrator === true)

                            if (hasViewerPermission === true) {
                                return { status: true };
                            }

                            if (hasViewerPermission === false && retry === MAXIMUM_LOGIN_RETRIES) {
                                return { status: false, message: ErrorCode.FORBIDDEN, retries: retry, claims: claims };
                            }

                            retry++;
                            await delay(3000);
                            return await getClaims(retry);
                        } catch (error) {
                            return { status: false, message: (error as FirebaseError).code, retries: retry };
                        }
                    },
                    claimsResponse = await getClaims();

                    if (claimsResponse.status === true) {
                        const sidebarData = await settingsService.getSitemap();
                        const homepageData = await HomepageService.getHomepage()

                        const criteria = {
                            email: authUser.email
                        }

                        const { data: user } = await UsersService.getAll(criteria);

                        const findDepartment = async () => {
                            const [managementDeps, practiceDeps, governanceDeps] = await Promise.all([
                                departmentsManagementService.getAll(),
                                departmentsPracticeService.getAll(),
                                departmentsGovernanceService.getAll()
                            ]);

                            let foundItem = null;

                            const userDepartment = user.response[0].department;

                            foundItem = managementDeps.find(dep => dep.data.name === userDepartment);
                            if (foundItem) {
                                return foundItem;
                            } else {
                                foundItem = practiceDeps.find(dep => dep.data.name  === userDepartment);
                                if (foundItem) {
                                    return foundItem;
                                } else {
                                    foundItem = governanceDeps.find(dep => dep.data.name  === userDepartment);
                                    if (foundItem) {
                                        return foundItem;
                                    }
                                }
                            }
                        };

                        const userDepartment = await findDepartment();

                        this.setState({
                            authUser: {
                                loading: false,
                                auth: authUser,
                                accessDenied: false,
                                ...(authUser.isAnonymous === false ? { claims: claims } : {}),
                            },
                            sidebar: sidebarData,
                            myKMHubLink: userDepartment?.data.myKMHubLink,
                            headerApps: homepageData?.headerApps
                        });
                    } else {
                        await LoginFailedEventService.setEvent({ 
                            user: authUser.uid, 
                            error: claimsResponse.message,
                            retries: claimsResponse.retries,
                            claims: claimsResponse.claims,
                            creationDate: new Date() 
                        })
                        
                        this.setState({
                            authUser: {
                                loading: false,
                                auth: authUser,
                                accessDenied: true,
                                ...(authUser.isAnonymous === false ? { claims: claims } : {}),
                            }
                        });
                    }
                });
            })
        }

        componentWillUnmount() {
            this.unsubscribe && this.unsubscribe();
        }

        toogleSidebar = (open: boolean) => {
            document.body.style.overflow = open ? 'hidden' : 'unset';
            this.setState({ open: open });
        }

        render() {
            if (this.state.authUser.auth === null) {
                return (
                    <Layout style={{ minHeight: '100vh' }}>
                        <Content>
                            <AuthenticationContext.Provider value={this.state.authUser}>
                                <Component {...this.props} />
                            </AuthenticationContext.Provider>
                        </Content>
                    </Layout>
                );
            }

            if (this.state.authUser.loading === true) {
                return;
            }

            return (
                <>
                    <Header
                        sidebarOpen={this.state.open}
                        myKMHubLink={this.state.myKMHubLink}
                        user={this.state.authUser}
                        onSelectSidebar={(open: boolean) => this.toogleSidebar(open)}
                        apps={this.state.headerApps}
                    />
                    <Content>
                        <Sidebar open={this.state.open} data={this.state.sidebar} apps={this.state.headerApps} toggleSidebar={this.toogleSidebar} />
                        <AuthenticationContext.Provider value={this.state.authUser}>
                            <Component {...this.props} />
                        </AuthenticationContext.Provider>
                    </Content>
                            <BackToTop/>
                    <Footer />
                </>
            );
        }
    }

    return WithAuthentication;
};

export default withAuthentication;